import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';
import { graphql } from 'gatsby';

import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import buildCollectionPageUrl from '../url/buildCollectionPageUrl';
import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

// Types
import { CollectionPageBreadcrumbFragment } from './__generated__/CollectionPageBreadcrumbFragment';

export const fragment = graphql`
  fragment CollectionPageBreadcrumbFragment on CMS_Collectionpage {
    slug
    title
  }
`;

type Props = {
  collectionPage: CollectionPageBreadcrumbFragment;
};

const CollectionPageBreadcrumb = ({ collectionPage }: Props) => {
  const collectionPageTitle = collectionPage.title;
  const collectionSlug = collectionPage.slug;

  if (!collectionPageTitle || !collectionSlug) {
    return null;
  }

  const collectionPageUrl = buildCollectionPageUrl(collectionSlug);

  const collectionListItem = buildBreadcrumbListItem({ name: collectionPageTitle, position: 2, url: collectionPageUrl });

  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM, collectionListItem],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default CollectionPageBreadcrumb;
