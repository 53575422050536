import React from 'react';
import { graphql } from 'gatsby';

import CollectionPageBreadcrumb from '../../../utils/seo/CollectionPageBreadcrumb';
import Heading from '../../../components/Heading';
import SEOContainer from '../../Layout/SEO';
import Section from '../../../components/Heading/Section';
import SectionContainer from '../../Section';
import buildCollectionSeoProps from '../../../utils/seo/buildCollectionSeoProps';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';
import { isNeighborClipped } from '../../Section/utils';

// Types
import { CollectionPageContainerFragment } from './__generated__/CollectionPageContainerFragment';

type Props = {
  collectionPage: CollectionPageContainerFragment;
};

export const fragment = graphql`
  fragment CollectionPageContainerFragment on CMS_Collectionpage {
    ...BuildCollectionSeoPropsFragment
    ...CollectionPageBreadcrumbFragment
    id
    collection_jk {
      id
    }
    sections(sort: "order") {
      ...SectionContainerFragment
      id
    }
  }
`;

const CollectionPageContainer = ({ collectionPage }: Props) => {
  const seoProps = buildCollectionSeoProps(collectionPage);

  const filteredSections = (collectionPage.sections || []).filter(notNullOrUndefined);
  const mappedSections = filteredSections.map((section, index, sections) => (
    <SectionContainer
      index={index}
      isCritical={true}
      isNeighborClipped={isNeighborClipped({ index, sections })}
      key={section.id}
      section={section}
    />
  ));

  return (
    <>
      {/* SEO */}
      <CollectionPageBreadcrumb collectionPage={collectionPage} />
      <SEOContainer {...seoProps} />

      {!!seoProps.title && <Heading isVisuallyHidden={true}>{seoProps.title}</Heading>}

      <Section hasNoTag={true}>
        <Heading isVisuallyHidden={true}>Collection Page</Heading>

        {mappedSections}
      </Section>
    </>
  );
};

export default CollectionPageContainer;
