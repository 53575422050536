import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import CollectionPageContainer from '../containers/Collection/CollectionPage';
import LayoutContainer from '../containers/Layout';
import buildCollectionOverviewUrl from '../utils/url/buildCollectionOverviewUrl';

// Types
import { CollectionPageTemplateQuery } from './__generated__/CollectionPageTemplateQuery';
import { PageRendererProps } from 'gatsby';

export const pageQuery = graphql`
  query CollectionPageTemplateQuery($where: CMS_JSON!) {
    cms {
      collectionpages(where: $where) {
        ...CollectionPageContainerFragment
        logoColor
        slug
      }
    }
  }
`;

type Props = {
  data: CollectionPageTemplateQuery;
} & PageRendererProps;

const CollectionPageTemplate = ({ data }: Props) => {
  if (!data.cms.collectionpages || data.cms.collectionpages.length !== 1) {
    throw Error('Something went wrong');
  }

  const [collectionPage] = data.cms.collectionpages;
  const logoColor = idx(collectionPage, _ => _.logoColor);
  const slug = idx(collectionPage, _ => _.slug);

  if (!collectionPage || !slug) {
    throw Error('Something went wrong');
  }

  const collectionUrl = buildCollectionOverviewUrl(slug);

  return (
    <LayoutContainer collectionOverviewUrl={collectionUrl} logoColor={logoColor}>
      <CollectionPageContainer collectionPage={collectionPage} />
    </LayoutContainer>
  );
};

export default CollectionPageTemplate;
