import idx from 'idx';
import { graphql } from 'gatsby';

import getSeoPicture from './getSeoPicture';
import buildCollectionPageUrl from '../url/buildCollectionPageUrl';

// Types
import { BuildCollectionSeoPropsFragment } from './__generated__/BuildCollectionSeoPropsFragment';

export const fragment = graphql`
  fragment BuildCollectionSeoPropsFragment on CMS_Collectionpage {
    slug
    title
    collection_jk {
      description
      id
      cover {
        ...SeoMetaPictureFragment
      }
    }
  }
`;

const buildCollectionSeoProps = (collectionPage: BuildCollectionSeoPropsFragment) => {
  const cover = idx(collectionPage, _ => _.collection_jk.cover) || null;
  const description = idx(collectionPage, _ => _.collection_jk.description);
  const slug = collectionPage.slug;
  const title = collectionPage.title;

  const canonicalUrl = buildCollectionPageUrl(slug);
  const image = getSeoPicture({ alt: title, picture: cover });

  return {
    canonicalUrl,
    description,
    image,
    openGraphDescription: description,
    openGraphTitle: title,
    title,
  };
};

export default buildCollectionSeoProps;
